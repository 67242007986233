import {useI18n} from 'vue-i18n'
import {translationExpanded} from '~/plugins/translation_helper'

export default function useI18nExtended() {
  const i18n = useI18n()
  return {
    ...i18n,
    tv: (value: string | object, translationPrefix: string, defaultValue?: string) =>
      translationExpanded(value, translationPrefix, defaultValue, i18n)
  }
}
